<template>
	<section class="col" align-x="left" :key="id">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item to="/manager/product">百貨舖</el-breadcrumb-item>
			<el-breadcrumb-item>{{name || "新增"}}</el-breadcrumb-item>
		</el-breadcrumb>
		<br>
		<br>
		<el-card>
			<el-form :model="$data" :rules="rules" ref="basicForm" label-width="60px">
				<el-form-item label="名稱" prop="name">
					<el-input v-model="name" class="round" :disabled="!checkEditable('name')" />
				</el-form-item>
				<el-form-item label="標語">
					<el-input v-model="title" type="textarea" class="round" :autosize="{minRows:3}" :disabled="!checkEditable('title')" />
				</el-form-item>
				<el-divider />
				<el-form-item label="定價" prop="price">
					<el-input v-model.number="price" type="number" class="round" :disabled="!checkEditable('price')">
						<template slot="suffix">NT$</template>
					</el-input>
				</el-form-item>
				<el-form-item label="椅友價" prop="special_price">
					<el-input v-model.number="special_price" type="number" class="round" :disabled="!checkEditable('special_price')">
						<template slot="suffix">NT$</template>
					</el-input>
				</el-form-item>
				<el-form-item label="狀態">
					<el-switch v-model="on_sale" active-text="上架" active-color="greenyellow" :disabled="!checkEditable('on_sale')" />
				</el-form-item>
				<el-form-item>
					<div class="row" align-x="right">
						<template v-if="checkEditable(['name', 'title', 'price', 'special_price', 'on_sale'])">
							<el-button @click="handleCancel(['name', 'title', 'price', 'special_price', 'on_sale'])" type="text">取消</el-button>
							<el-button @click="handleUpdateData(['name', 'title', 'price', 'special_price', 'on_sale'])" type="success">儲存</el-button>
						</template>
						<template v-else>
							<el-button @click="handleEdit(['name', 'title', 'price', 'special_price', 'on_sale'])" type="text" icon="el-icon-edit">編輯</el-button>
						</template>
					</div>
				</el-form-item>
			</el-form>
		</el-card>
		<br>
		<div class="row-grid" gutter-x="20" gutter-y="20">
			<div class="col">
				<p>上傳縮圖</p>
				<el-card>
					<div class="row" gutter="10">
						<div>
							<upload-thumb v-model="thumb" square :disabled="!checkEditable('thumb')" />
						</div>
						<div class="row" align-self="bottom">
							<template v-if="checkEditable('thumb')">
								<el-button @click="handleCancel('thumb')" type="text">取消</el-button>
								<el-button @click="handleUpdateData('thumb')" type="success">儲存</el-button>
							</template>
							<template v-else>
								<el-button @click="handleEdit('thumb')" type="text" icon="el-icon-edit">編輯</el-button>
							</template>
						</div>
					</div>
				</el-card>
			</div>
			<div class="col">
				<p>上傳商品圖片</p>
				<el-card>
					<div class="row" gutter="10">
						<div>
							<upload v-model="pictures" multiple :disabled="!checkEditable('pictures')" />
						</div>
						<div class="row" align-self="bottom">
							<template v-if="checkEditable('pictures')">
								<el-button @click="handleCancel('pictures')" type="text">取消</el-button>
								<el-button @click="handleUpdateData('pictures')" type="success">儲存</el-button>
							</template>
							<template v-else>
								<el-button @click="handleEdit('pictures')" type="text" icon="el-icon-edit">編輯</el-button>
							</template>
						</div>
					</div>
				</el-card>
			</div>
		</div>
		<br>
		<br>
		<p>上傳商品內容</p>
		<div class="row-grid" gutter-x="20" gutter-y="20">
			<div v-for="(item, i) in options" :key="i">
				<el-badge :hidden="!!item.amount" value="缺">
					<el-card>
						<el-form :model="item" :rules="rules" ref="ruleForm" label-width="60px">
							<el-tooltip content="沒有「顏色、尺寸」可不填" placement="top" :disabled="!item.editable && !item.isNew">
								<div>
									<el-form-item label="顏色">
										<el-autocomplete v-model="item.color" :fetch-suggestions="fetchSuggestions('color_list')" :placeholder="(item.editable || item.isNew) && !item.color ? '例如：洋紅色' : '無'" class="round" :disabled="!item.editable && !item.isNew" />
									</el-form-item>
									<el-form-item label="尺寸">
										<el-autocomplete v-model="item.size" :fetch-suggestions="fetchSuggestions('size_list')" :placeholder="(item.editable || item.isNew) && !item.size ? '例如：S' : '無'" class="round" :disabled="!item.editable && !item.isNew" />
									</el-form-item>
								</div>
							</el-tooltip>
							<el-form-item label="庫存">
								<el-input-number v-model="item.amount" :min="0" :disabled="!item.editable && !item.isNew" />
							</el-form-item>
						</el-form>
						<div class="row" align-x="right">
							<template v-if="item.editable || item.isNew">
								<el-button @click="handleCancel('options')" type="text">取消</el-button>
								<template v-if="item.isNew">
									<el-button @click="handleCreateOption(i)" type="success">新增</el-button>
								</template>
								<template v-else>
									<el-button @click="handleUpdateOption(i)" type="success">儲存</el-button>
								</template>
							</template>
							<template v-else>
								<div class="row" align-y="center" gutter="20">
									<el-button icon="el-icon-edit" type="text" @click="$set(item, 'editable', true)">編輯</el-button>
									<popconfirm @confirm="handleRemoveOption(item.id)">
										<h3><i class="el-icon-info" style="color:red;" />{{"\u3000"}}確定要刪除？</h3>
										<el-button slot="reference" type="danger" icon="el-icon-delete" size="mini" circle />
									</popconfirm>
								</div>
							</template>
						</div>
					</el-card>
				</el-badge>
			</div>
			<div class="center">
				<el-button @click="handleAddOption()" icon="el-icon-plus" type="primary" circle />
			</div>
		</div>
		<br>
		<br>
		<p>編輯內容</p>
		<el-card>
			<template v-if="checkEditable('details')">
				<tinymce v-model="details" />
				<div class="center">
					<el-button @click="handleCancel('details')" type="info">取消</el-button>
					<el-button @click="handleUpdateData('details')" type="success">儲存</el-button>
				</div>
			</template>
			<template v-else>
				<section v-html="details" />
				<div class="center">
					<el-button @click="handleEdit('details')" icon="el-icon-edit">編輯</el-button>
				</div>
			</template>
		</el-card>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
const optionData = {
	color: "",
	size: "",
	amount: 0
}
export default {
	components: {
		UploadThumb: () => import("@/components/upload-thumb"),
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce"),
		Popconfirm: () => import("@/components/popconfirm"),
	},
	props: ["id"],
	data() {
		return {
			editable: new Object,
			name: "",
			title: "",
			thumb: "",
			pictures: [],
			price: 0,
			special_price: 0,
			on_sale: false,
			options: new Array,
			details: "",
			rules: {
				name: { required: true, message: "必填", trigger: "blur" },
				price: [
					{ required: true, message: "必填", trigger: "blur" },
					{ type: "number", message: "請填入數字", trigger: "blur" },
				],
				special_price: [
					{ required: true, message: "必填", trigger: "blur" },
					{ type: "number", message: "請填入數字", trigger: "blur" },
				],
			},
		}
	},
	computed: {
		...mapState("product", ["_product", "_autocomplete_option"]),
	},
	methods: {
		...mapActions("product", [
			"_getData",
			"_updateData",
			"_removeData",
			"_createOption",
			"_updateOption",
			"_removeOption",
			"_getAutocompleteOption",
		]),
		/**
		 * 更新除了「商品內容」以外其他資訊
		 * @param {(String | String[])} key 要更新的 key name
		 */
		async handleUpdateData(key) {
			const keys = Array.isArray(key) ? key : [key];
			try {
				this.$message.success(
					await this._updateData({
						id: this.id,
						data: this._.pick(this.$data, keys)
					})
				);
				keys.forEach(k => this.$set(this.editable, k, false));
			} catch (error) { }
		},
		/**
		 * 取消更新
		 * @param {(String | String[])} key 要取消的 key name
		 */
		handleCancel(key) {
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => {
				this.$set(this.$data, k, this._.cloneDeep(this._product[k]));
				this.$set(this.editable, k, false);
			});
		},
		/**
		 * 檢查是否可以編輯
		 * @param {(String | String[])} key 要檢查的 key name
		 *
		 * @return { Boolean } 是否為可編輯狀態
		 */
		checkEditable(key) {
			const keys = Array.isArray(key) ? key : [key];
			return keys.every(k => this.editable[k]);
		},
		/**
		 * 打開編輯
		 * @param {(String | String[])} key 要開啟編輯的 key name
		 */
		handleEdit(key) {
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => this.$set(this.editable, k, true));
		},
		fetchSuggestions(key) {
			return (string, callback) => {
				const list = this._autocomplete_option[key] || new Array;
				const results = string
					? list.filter(item => item.includes(string))
					: list;
				// 調用 callback 返回建議列表的數據
				callback(results.map(value => ({ value })));
			}
		},
		handleAddOption() {
			if (!Array.isArray(this.options)) this.options = new Array;
			this.options.push({
				...optionData,
				isNew: true
			});
		},
		handleCreateOption(i) {
			this.$refs["ruleForm"][i].validate(async valid => {
				if (valid) {
					const { message, data } = await this._createOption({
						id: this.id,
						data: this.options[i]
					});
					this.$set(this.options, i, data);
					this.$message.success(message);
				}
			})
		},
		handleUpdateOption(i) {
			this.$refs["ruleForm"][i].validate(async valid => {
				if (valid) {
					const { message, data } = await this._updateOption({
						index: i,
						data: {
							id: this.id,
							optionId: this.options[i].id,
							data: this.options[i]
						}
					});
					this.$set(this.options, i, data);
					this.$message.success(message);
				}
			})
		},
		async handleRemoveOption(optionId) {
			const { message, data } = await this._removeOption({
				id: this.id,
				optionId
			});
			this.$set(this.$data, "options", data);
			this.$message.success(message);
		}
	},
	async created() {
		this._.assign(this.$data, await this._getData(this.id));
		if (!Object.keys(this._autocomplete_option).length) await this._getAutocompleteOption();
	}
}
</script>

<style lang="scss">
.el-tooltip__popper {
	font-size: 1em;
}
</style>